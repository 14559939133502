import React, { Component } from "react";
import { Form, Input, Modal, Button, Label,Alert,message,Select,Checkbox,DatePicker, Row, Col } from 'antd';
import {
  Redirect,
  HashRouter,
  withRouter,
  Link
} from "react-router-dom";
import Footer from '../Footer2'
import restClient from '../network/restClient';

class Mvv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            mision:'',
            vision:'',
            valores:[],
            image:'',
            otherItems: [],
        };
    }

    componentDidMount(){
            this.getPageContent();
      }

     getPageContent = ()=>{
               this.setState({loading:true});
               restClient.getIdearioContent().then(response=>{
                let coreValues = [];
                const items = response.data;
                let ms;
                let vs;
                let cover;
                items.map(ideario=>{
                    if(ideario.tipo=='valores') {
                        ideario.key=`p-${ideario.id_ideario}`;
                        coreValues.push(ideario);
                    }
                    if(ideario.nombre=='mision') {
                        ms=ideario.valor;
                    }
                    if(ideario.nombre=='vision') {
                        vs=ideario.valor;
                    }
                    if(ideario.nombre=='bg-photo'){
                        cover=ideario.valor;
                    }
                });
                const otherItems = items.filter(item => item.tipo !== 'foto' && item.tipo !== 'mv' && item.tipo !== 'valores');
                this.setState({mision:ms,
                    vision:vs,
                    valores:coreValues,
                    image:cover,
                    otherItems,
                    loading:false})
                 }).catch(error=>{
                    this.setState({loading:false});
                    message.error(error.message);
                });
        }

  render() {
     const{mision,vision,valores,image}=this.state
    return (
    <div className="wrapper">
     <Row type="flex" className="rowHeader">
            <Col md={12} xs={24}>
                <div class="headerBackground ideario">
                    <img src={image} />
                </div>
            </Col>
            <Col md={12} xs={24} className="bgColorHeader">
                <div className="headerText mvvContainerHeader">
                    <div className="bigTitHeader mvv">
                        PROPÓSITO
                    </div>
                    <p className="shortPar colorWhite">
                        Formar mujeres líderes que transformen el mundo en un lugar más humano, justo y solidario.
                    </p>
                    <div className="bigTitHeader mvv">
                        MISIÓN
                    </div>
                    <p className="shortPar colorWhite">
                        {mision}
                    </p>
                    <div className="bigTitHeader mvv">
                        VISIÓN
                    </div>
                    <p className="shortPar colorWhite">
                        {vision}
                    </p>
                </div>
            </Col>
        </Row>

        <Row className="coreValuesRow">

            <div className="valueTit light big">
                VALORES
            </div>
            {valores.map(coreValue=>
                <div className="valuesList">
                    <strong>
                        {coreValue.nombre}  <br></br>
                    </strong>
                    {coreValue.valor}
                </div>
            )}
        </Row>
        <Row className="coreValuesRow">
            {this.state.otherItems?.map(item => (
                <div>
                    <div className="valueTit light big">
                        {item.nombre}
                    </div>
                    <div className="valuesList">
                        <div dangerouslySetInnerHTML={{ __html: item.valor }}></div>
                    </div>
                </div>
            ))}
        </Row>
        <Footer/>

    </div>
    )
    }
}
export default Mvv;
