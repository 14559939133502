import React, { Component } from "react";
import { Form, Input, Modal, Button, Label,Alert,message,Select,Checkbox,DatePicker, Row, Col } from 'antd';
import {
  Redirect,
  HashRouter,
  withRouter,
  Link
} from "react-router-dom";
import directoraPhoto from '../images/directora-23.jpg';
import Footer from '../Footer2'
import restClient from '../network/restClient';

class Equipo extends Component {
    constructor(props) {
    super(props);
        this.state = {
            loading:false,
            data:'',
            directora:'',
            people:[],
            direccion:[]
         
        }
        this.myRef = React.createRef();
    }

    scrollToMyRef = () => {

        window.scrollTo({
            top:190,
            // behavior: "smooth" // optional
        });
    };

  componentDidMount(){

      this.setState({loading:true})
      this.getContent()
      this.getPeople()


  }

  getContent = () =>{
      restClient.getContentById({id_page:17}).then(response=>{
               this.setState({data:response.data[0]},()=>this.scrollToMyRef());
      }).catch(error=>{
               this.setState({loading:false});
               message.error(error.message);
      });
  }

  getPeople = () =>{
      restClient.getTeam().then(response=>{
          let personas = [];
          let direccion = [];
          let iPerson=1;
          let iDireccion=1;
          let directora=''; 
            response.data.map(person=>{
                person.cargo = person.cargo.toUpperCase();
                personas.push(person);
                // if(person.id_equipo===14){
                //     person.cargo = "Coord. Comunicación y<br>Proyección Institucional";                
                // }

                // if(person.direccion===true){
                //     if(person.id_equipo===1){
                //         directora = person             
                //     }    else {
                //         person.key=iDireccion
                //         iDireccion+=1                                      
                //         direccion.push(person)
                //     }
                 
                    
                // } else {
                //     person.key=iPerson
                //     iPerson+=1
                //     personas.push(person)
                // }


            })
            console.log(personas)
            console.log(direccion)
           this.setState({people:personas,
                          direccion,  
                          directora,                                        
                          loading:false})
           }).catch(error=>{
               this.setState({loading:false});
               message.error(error.message);
         });
  }

  render() {
      const{data,direccion,directora,people}=this.state
    return (
    <div className="wrapper">

<Row type="flex" className="rowHeader">
        
                <Col md={24} xs={24} className="bgColorHeader">
                    <div className="headerText eequipo">
                     <div className="bigTitHeader acad shortTit pBottom equipoPage">
                            EL Equipo
                     </div>
                      <p className="shortPar espaceTop forma">
                          {data.valor2}
                      </p>
                    </div>
                </Col>
            </Row>
            {/* <Row type="flex" className="topRowDir">
                    <Col md={12} xs={24}>
                        <div className="dirBox">
                            <img src={directoraPhoto} className="dirImg"></img>
                        </div>
                    </Col>
                    
                    <Col md={12} xs={24}
                            className="eqCol">
                            <div className="equipoBox">
                                <div className="name">
                                    {directora.nombre}
                                </div>
                                <div className="cargo">
                                <div dangerouslySetInnerHTML={{ __html: directora.cargo }}></div>

                                </div>
                                <div className="email equipo">
                                    {directora.correo}
                                </div>
                            </div>
                        </Col>
            </Row> */}

        {/* <Row type="flex" className="directoraRow">
            {direccion.map(person=>      
            <Col md={12} xs={24}
                className={person.key%2==0 ? "alignLeftCol pbottom":"alignRightCol pbottom"}>
                <div className="equipoBox">
                    <div className="name">
                        {person.nombre}
                    </div>
                    <div className="cargo">
                         <div dangerouslySetInnerHTML={{ __html: person.cargo }}></div>
                    </div>
                    <div className="email equipo">
                        {person.correo}
                    </div>
                </div>
                </Col>
            )}
        </Row> */}

          
        {/* <Row className="center">
              <div className="darkLine">
              </div>
              <div className="boldTextEquipo">
              OTRAS PERSONAS
              </div>
              <div className="lightTextEquipo">
                  QUE PUEDEN AYUDARTE
              </div>
        </Row>

        <Row type="flex" className="directoraRow">
            {people.map(person=>
                <Col md={12} xs={24}
                    className={person.key%2==0 ? "alignLeftCol pbottom":"alignRightCol pbottom"}>
                    <div className="equipoBox">
                        <div className="name">
                            {person.nombre}
                        </div>
                        <div className="cargo">
                            <div dangerouslySetInnerHTML={{ __html: person.cargo }}></div>
                        </div>
                        <div className="email equipo">
                            {person.correo}
                        </div>
                    </div>
                </Col>
            )}
        </Row> */}
        <div style={{margin:'auto', margin:'60px auto', maxWidth:'1024px'}}>
            <Row gutter={20}>
            {people.map(person=>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">{person.nombre}</div>
                        <div className="cargoEquipo"><div dangerouslySetInnerHTML={{ __html: person.cargo }}></div></div>
                        <div className="mailEquipo">{person.correo}</div>
                    </div>
                </Col>
            )}
            </Row>   
        </div>
        {/* <div style={{margin:'auto', marginTop:50, maxWidth:'1024px'}}>
            <Row gutter={20}>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Marilú Arosemena de Ginatta</div>
                        <div className="cargoEquipo">DIRECTORA GENERAL</div>
                        <div className="mailEquipo">marosemena@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Roxana Castillo de Piana</div>
                        <div className="cargoEquipo">RECTORA</div>
                        <div className="mailEquipo">rcastillo@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Aglay Ibarra de Lynch</div>
                        <div className="cargoEquipo">DIRECTORA DE FORMACIÓN</div>
                        <div className="mailEquipo">aibarra@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Daniella Medina de Massúh</div>
                        <div className="cargoEquipo">DIRECTORA DE ASESORAMIENTO FAMILIAR</div>
                        <div className="mailEquipo">dmedina@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Rafaela Menoscal de Guerrero</div>
                        <div className="cargoEquipo">VICERRECTORA SUPERIOR Y BACHILLERATO</div>
                        <div className="mailEquipo">rmenoscal@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Katherine Guerrero</div>
                        <div className="cargoEquipo">VICERRECTORA ELEMENTAL Y MEDIA</div>
                        <div className="mailEquipo">kguerrero@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Jéssica Maya</div>
                        <div className="cargoEquipo">COORDINADORA ELEMENTAL</div>
                        <div className="mailEquipo">jmaya@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Carlota Alvarado de Illingworth</div>
                        <div className="cargoEquipo">COORDINADORA MEDIA</div>
                        <div className="mailEquipo">calvarado@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Gisella Casal de Grunauer</div>
                        <div className="cargoEquipo">COORDINADORA SUPERIOR Y BACHILLERATO</div>
                        <div className="mailEquipo">gcasal@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Daniela Portugal</div>
                        <div className="cargoEquipo">COORDINADORA INGLÉS</div>
                        <div className="mailEquipo">dportugal@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Claudia Macchiavello</div>
                        <div className="cargoEquipo">COORDINADORA IB/COUNSELOR</div>
                        <div className="mailEquipo">mmachiavello@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Andrea Laniado de Diéguez</div>
                        <div className="cargoEquipo">COORDINADORA COMUNICACIÓN Y ADMISIONES</div>
                        <div className="mailEquipo">alaniado@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Lissette Faggioni de Jurado</div>
                        <div className="cargoEquipo">COORDINADORA ADMINISTRATIVA</div>
                        <div className="mailEquipo">lfaggioni@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Carla Villena de Chon</div>
                        <div className="cargoEquipo">COORDINADORA DE CALIDAD</div>
                        <div className="mailEquipo">cvillena@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Ma. Gabriella Ottati de Castillo</div>
                        <div className="cargoEquipo">DIRECTORA PREESCOLAR</div>
                        <div className="mailEquipo">mottati@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Ma. Del Rocío Pino de Jácome</div>
                        <div className="cargoEquipo">DIRECTORA FAMILIA PREESCOLAR</div>
                        <div className="mailEquipo">mpino@uedelta.edu.ec</div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="bloqueMailEquipo">
                        <div className="tituloEquipo">Ma. Teresa Vargas de Tama</div>
                        <div className="cargoEquipo">DIRECTORA FORMACIÓN PROFESORAS PREESCOLAR</div>
                        <div className="mailEquipo">mvargas@uedelta.edu.ec</div>
                    </div>
                </Col>
            </Row>   
        </div> */}
        <Row className="center">
              {/* <img src="https://colegio-delta.s3.amazonaws.com/organigrama.jpg" style={{margin:'30px auto'}}/> */}
        </Row>
        <Footer/>

    </div>
    )
    }
}
export default Equipo;
